<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Notificaçõs abertas no sistema" />

		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-6">
				<button
					@click="router.back()"
					class="mb-5 p-2 rounded-full mr-4 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 flex items-center active:bg-gray-400 active:rounded-full"
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
					<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
				</button>
			</div>
		</div>
		<button @click="router.push(`/admin/suporte/notify/form/`)" class="mb-5 px-6 py-2 bg-green-700 rounded-full flex items-center hover:bg-green-800">
			<span class="select-none cursor-pointer text-sm dark:text-white">+ Criar aviso</span>
		</button>

		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200" v-if="avisos?.length > 0">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Data</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">User</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Titulo</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Comentários</th>
						<th class="sm:px-2 py-3 text-end dark:text-zinc-200">Opções</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr
						class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800"
						v-for="aviso in avisos.slice().reverse()"
						:key="aviso._id"
						:class="aviso.root ? 'bg-blue-300/[.08]' : ''"
					>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ moment(aviso.data).format("DD/MM HH:mm") }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ aviso.userName }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ aviso.titulo }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ aviso.descricao }}</th>
						<th class="text-center flex soace-x-2 justify-center h-full">
							<button
								@click="router.push(`/admin/suporte/notify/form/${aviso._id}`)"
								class="mx-1 mb-1 mt-2 rounded-md px-2 py-1 text-sm bg-yellow-600 text-white border-b-2 border-r-2 border-yellow-800 transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-800 hover:text-opacity-50"
							>
								editar
							</button>

							<button
								v-if="!aviso.status"
								@click="publicarRetirar(aviso)"
								class="mx-1 mb-1 mt-2 rounded-md px-2 py-1 text-sm bg-green-600 text-white border-b-2 border-r-2 border-green-800 transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
							>
								publicar
							</button>
							<button
								v-if="aviso.status"
								@click="publicarRetirar(aviso)"
								class="mx-1 mb-1 mt-2 rounded-md px-2 py-1 text-sm bg-red-600 text-white border-b-2 border-r-2 border-red-800 transition duration-300 ease-in-out hover:shadow-md hover:bg-red-800 hover:text-opacity-50"
							>
								retirar
							</button>
						</th>
					</tr>
				</tbody>
			</table>
			<p v-else class="text-center font-bold text-gray-500 dark:text-zinc-200">Ops, sem notificações até o momento!</p>
		</div>
	</div>
</template>
<script setup>
	import {onBeforeMount, ref} from "vue";
	import {useRoute, useRouter} from "vue-router";
	import api from "@/services/api.js";
	import TituloPagina from "@/components/tituloPagina.vue";
	import moment from "moment";
	import {toast} from "@/hooks/toast";

	const route = useRoute();
	const router = useRouter();

	const avisos = ref([]);

	const publicarRetirar = async aviso => {
		let status = !aviso.status;

		const req = await api.put(`/v1/notify`, {_id: aviso._id, status: status});

		if (req.data.success) {
			toast("Aviso publicado/retirado com sucesso!", "success");

			const req = await api.get(`/v1/notify`);
			avisos.value = req.data.data;
		} else {
			return toast(req.data.err);
		}
	};

	onBeforeMount(async () => {
		const req = await api.get(`/v1/notify`);
		if (req.data.data) {
			avisos.value = req.data.data;
		}
	});
</script>
