<template>
	<vue-final-modal v-model="showModal" name="baixarVolumeLzv2" class="overflow-auto">
		<div id="config" class="bg-gray-100 dark:bg-dark rounded-md h-auto w-96 mx-auto translate-y-36 p-6">
			<svg
				@click="useModal('baixarVolumeLzv2', $vfm)"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="text-red-500 w-6 h-6 cursor-pointer absolute top-2 right-5"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
			</svg>

			<h3 class="text-lg font-semibold text-header dark:text-white 700 mb-3" v-if="props.fazerDownload">
				Volume LZV2: <span class="text-gray-500">{{ volume }}</span>
			</h3>
			<div class="grid grid-cols-12 gap-4">
				<div class="col-span-12">
					<label class="flex gap-5 text-header dark:text-white text-sm mb-2">
						Enviar de todos os usuários (hoje)
						<input :checked="enviarDosUsuariosDeHoje" type="checkbox" @input="() => (enviarDosUsuariosDeHoje = !enviarDosUsuariosDeHoje)" />
					</label>
				</div>
				<div class="col-span-12" v-if="!props.fazerDownload">
					<label class="flex gap-5 text-header dark:text-white text-sm mb-2">
						Enviar maturação (defina na conta)
						<input :checked="enviarMaturacao" type="checkbox" @input="() => (enviarMaturacao = !enviarMaturacao)" />
					</label>
				</div>
				<div class="col-span-12">
					<label class="block text-header dark:text-white text-sm mb-2">Baixar deste usuário</label>
					<v-select
						v-model="usuario_select"
						:options="usuarios_select"
						:reduce="option => option._id"
						multiple
						label="email"
						placeholder="Selecione um usuário"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12" v-if="props.fazerDownload && usuario_select && usuario_select.length === 1">
					<label class="block text-header dark:text-white text-sm mb-2">Escolha o Centro de Custo</label>
					<v-select
						v-model="centroDeCusto"
						:options="centrosDeCusto"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12">
					<label class="block text-header dark:text-white text-sm mb-2"
						>Quantidade
						{{ props.fazerDownload ? "baixar" : typeof props.idZap === "string" ? "envios" : `envios por número (total de números: ${props.idZap.length})` }}</label
					>
					<CampoDeTexto tipo="number" v-model="qtdBaixar" class="w-full h-10" holder="Selecione a quantidade" />
				</div>
				<a
					v-if="props.fazerDownload"
					:href="`${url}/baixarVolumeLZV2/${qtdBaixar}?usuario=${
						usuario_select && usuario_select.length > 0 ? encodeURIComponent(JSON.stringify(usuario_select)) : ''
					}&centrocusto=${centroDeCusto && centroDeCusto._id ? centroDeCusto._id : ''}`"
					target="_blank"
					class="px-4 col-span-12 my-5 py-2 w-full rounded-md text-center text-white text-sm font-medium bg-blue-600 dark:bg-zinc-800 p-2 border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Baixar volume
				</a>
				<button
					v-else
					@click="criarEnvioCampanha"
					class="px-4 col-span-12 my-5 py-2 w-full rounded-md text-center text-white text-sm font-medium bg-blue-600 dark:bg-zinc-800 p-2 border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Criar campanha e enviar quantidade: {{ qtdBaixar }}
				</button>
			</div>
		</div>
	</vue-final-modal>
</template>
<script setup>
	import {ref, computed, watch, onBeforeMount, inject} from "vue";
	import {useStore} from "vuex";
	import {pega_contas, pega_usuarios} from "@/store/typeActions.js";
	import {toast} from "@/hooks/toast";
	import useModal from "@/hooks/useModal";
	import api from "@/services/api.js";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import moment from "moment";
	import Swal from "sweetalert2";

	const props = defineProps({
		fazerDownload: Boolean,
		idZap: {
			type: [String, Array],
			required: true,
		},
	});

	const store = useStore();
	const showModal = ref(false);
	const enviarDosUsuariosDeHoje = ref(false);
	const enviarMaturacao = ref(false);
	const volume = ref(0);
	const qtdBaixar = ref(props.fazerDownload ? 500 : 15);
	const centrosDeCusto = ref([]);
	const centroDeCusto = ref(null);
	const usuario_select = ref([]);
	const usuarios_select = computed(() => store.state.usuarios);
	const clientesQueEnviaramHoje = computed(() => store.state.contas);
	const $vfm = inject("$vfm");
	const url = `${process.env.VUE_APP_API}`;

	const criarEnvioCampanha = async () => {
		const ehUmaString = typeof props.idZap === "string";

		if (!props.idZap || (ehUmaString && props.idZap.trim() === "") || (Array.isArray(props.idZap) && props.idZap.length === 0)) {
			return toast("Sem id do zap para envio", "error");
		}

		if (qtdBaixar.value === 0) {
			return toast("Selecione a quantidade para fazer envio", "error");
		}

		if (!ehUmaString) {
			const {isConfirmed} = await Swal.fire({
				title: `Confirmação de envio para ${props.idZap.length} zaps`,
				text: `Deseja enviar ${qtdBaixar.value} por cada zap? Total de zaps: ${props.idZap.length}.\n Lista de zaps: \n ${props.idZap.map(x => x.telefone).join(", ")}.`,
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
			});

			if (!isConfirmed) {
				return;
			}
		}

		const response = await api.post("v1/zap/gerarCampanha", {
			quantidade: qtdBaixar.value,
			idZap: ehUmaString ? props.idZap : props.idZap.map(x => x._id),
			usuarios: usuario_select.value,
			enviarMaturacao: enviarMaturacao.value,
		});

		$vfm.hide("baixarVolumeLzv2");

		enviarDosUsuariosDeHoje.value = false;
		usuario_select.value = [];
		centroDeCusto.value = null;
		qtdBaixar.value = 15;

		if (response.data.success) {
			return toast("Campanha criada com sucesso", "success");
		}

		return toast(response.data.err, "error");
	};

	const buscaCentrosDeCusto = async usuario => {
		const response = await api.post(`/v1/centrocusto/list`, {usuario: usuario});
		centrosDeCusto.value = response.data.data;
	};

	onBeforeMount(async () => {
		const [_, __, reqVolume] = await Promise.all([
			store.dispatch(pega_usuarios, false),
			store.dispatch(pega_contas, {
				de: moment().startOf("day").toDate(),
				ate: moment().endOf("day").toDate(),
				resultrel: true,
			}),
			api.post("/v1/campanhasLZV2/volumeLZV2"),
		]);
		volume.value = reqVolume.data;
	});

	watch([usuario_select], async () => {
		if (props.fazerDownload && usuario_select.value && usuario_select.value.length > 1) {
			await buscaCentrosDeCusto(usuario_select.value);
			if (centroDeCusto) centroDeCusto.value = null;
		}
	});

	watch([enviarDosUsuariosDeHoje], () => {
		if (enviarDosUsuariosDeHoje.value) {
			usuario_select.value = clientesQueEnviaramHoje.value.map(cliente => ({
				_id: cliente.usuario._id,
				email: cliente.usuario.email,
			}));
		} else {
			usuario_select.value = null;
		}
	});
</script>
