<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Relatório" />
		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-6 md:col-span-2">
				<label class="text-zinc-500 dark:text-white">A partir de</label>
				<input
					type="date"
					@input="event => (data.de = event.target.value)"
					:value="data.de"
					class="w-full border border-gray-200 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white rounded-md text-center px-2 h-9"
				/>
			</div>
			<div class="col-span-6 md:col-span-2">
				<label class="text-zinc-500 dark:text-white">Até</label>
				<input
					type="date"
					@input="event => (data.ate = event.target.value)"
					:value="data.ate"
					class="w-full border border-gray-200 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white rounded-md text-center px-2 h-9"
				/>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="text-zinc-500 dark:text-white">Atualizar relatório</label>
				<button
					@click="atualizarRelatorio()"
					class="bg-blue-600 dark:bg-zinc-800 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Buscar
				</button>
			</div>
			<div class="col-span-12 md:col-span-2">
				<label class="text-zinc-500 dark:text-white">Download da tabela de envios</label>
				<button
					@click="download()"
					class="bg-blue-600 dark:bg-zinc-800 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Download CSV
				</button>
			</div>
			<div class="col-span-12 md:col-span-2" v-if="!usuario.financeiro">
				<label class="text-zinc-500 dark:text-white">Baixar quantidade para envio</label>
				<button
					@click="mostraModal"
					class="bg-blue-600 dark:bg-zinc-800 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Baixar Volume LZV2
				</button>
			</div>
			<div class="col-span-12 md:col-span-2" v-if="!usuario.financeiro">
				<label class="text-zinc-500 dark:text-white">Visualize envios</label>
				<button
					@click="router.push(`/admin/envios-clientes`)"
					class="bg-blue-600 dark:bg-zinc-800 p-2 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 dark:border-zinc-900 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 dark:hover:bg-zinc-900 hover:text-opacity-50"
				>
					Envios LZV2
				</button>
			</div>
		</div>
		<h3 class="text-base font-medium text-blue-700 dark:text-zinc-200 mt-3">Upload massivo</h3>
		<label className="block text-zinc-600 dark:text-zinc-200 cursor-pointer my-2 md:w-1/6" v-if="!usuario.financeiro">
			<input id="desativados" type="checkbox" :checked="mostraQTDEnviada" @input="() => (mostraQTDEnviada = !mostraQTDEnviada)" />
			Exibir quantidade enviada
		</label>
		<div class="bg-white dark:bg-black mb-5 px-2 py-2 shadow-md rounded-lg overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200" v-if="!exibirLoad">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-bas">
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200">Cliente</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200" v-if="mostraQTDEnviada && !usuario.financeiro">Quantidade enviada</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200">Total upload cliente (sucesso)</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200">Respostas Gerais</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800" v-for="campanha in campanhas" :key="campanha._id">
						<th class="sm:px-2 py-3 dark:text-cyan-500 text-blue-800 font-medium">
							{{ campanha.cliente.nome
							}}<b>{{
								campanha.cliente.lzv2 && campanha.cliente.lzv2.cliente_trestto
									? " - Trestto"
									: campanha.cliente.lzv2 && campanha.cliente.lzv2.cliente_novavida
									? " - Nova Vida"
									: " "
							}}</b>
						</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium" v-if="mostraQTDEnviada && !usuario.financeiro">
							{{ campanha.totalEnviosSistema.toLocaleString() }} ({{ getPorcentagem(campanha.totalEnviosSistema, campanha.totalEnviado) }}%)
						</th>
						<th class="sm:px-2 py-3 text-purple-700 font-medium dark:text-purple-500">{{ campanha.totalEnviado.toLocaleString() }}</th>
						<th class="sm:px-2 py-3 text-blue-500 font-medium">
							{{ campanha.respostas.toLocaleString() }} ({{ getPorcentagem(campanha.respostas, campanha.totalEnviado) }}%)
						</th>
					</tr>
				</tbody>
			</table>
			<Load v-else />
		</div>
		<h3 class="text-base font-medium text-blue-700 dark:text-zinc-200 my-3">Conversas iniciadas manualmente</h3>
		<div class="bg-white dark:bg-black my-2 px-2 py-2 shadow-md rounded-lg overflow-auto">
			<table class="w-full divide-y divide-gray-200" v-if="!exibirLoad">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-base">
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200">Cliente</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200" v-if="!usuario.financeiro">BlackList</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200" v-if="!usuario.financeiro">Reais</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200" v-if="!usuario.financeiro">Fakes</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200">Total</th>
						<th class="sm:px-2 py-3 font-semibold dark:text-zinc-200">Respostas Conversas</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200">
					<tr class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800" v-for="(conversa, index) in conversas" :key="index">
						<th class="sm:px-2 py-3 dark:text-cyan-500 text-blue-800 font-normal">{{ conversa.cliente }}</th>
						<th class="sm:px-2 py-3 dark:text-sky-600 text-black font-medium" v-if="!usuario.financeiro">{{ conversa.blacklist.toLocaleString() }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium" v-if="!usuario.financeiro">{{ conversa.reais.toLocaleString() }}</th>
						<th class="sm:px-2 py-3 dark:text-orange-400 text-orange-600 font-medium" v-if="!usuario.financeiro">{{ conversa.fakes.toLocaleString() }}</th>
						<th class="sm:px-2 py-3 text-purple-700 font-medium dark:text-purple-500">{{ conversa.total.toLocaleString() }}</th>
						<th class="sm:px-2 py-3 text-blue-500 font-medium">
							{{ conversa.respondidas.toLocaleString() }} ({{ ((conversa.respondidas * 100) / conversa.total || 0).toFixed(2) }}%)
						</th>
					</tr>
				</tbody>
			</table>
			<Load v-else />
		</div>

		<ModalBaixarVolume :idZap="null" :fazerDownload="true" />
	</div>
</template>
<script setup>
	import {computed, ref, inject, onBeforeMount} from "vue";
	import {useStore} from "vuex";
	import {toast} from "@/hooks/toast";
	import {useRouter} from "vue-router";
	import Load from "@/components/loading.vue";
	import moment from "moment";
	import api from "@/services/api.js";
	import TituloPagina from "@/components/tituloPagina.vue";
	import ModalBaixarVolume from "./modalBaixarVolume.vue";

	const store = useStore();
	const router = useRouter();
	const $vfm = inject("$vfm");
	const data = ref({
		de: moment().startOf("day").format("YYYY-MM-DD"),
		ate: moment().endOf("day").format("YYYY-MM-DD"),
	});
	const mostraQTDEnviada = ref(true);
	const conversas = ref([]);
	const campanhas = ref([]);
	const exibirLoad = ref(false);
	const usuario = computed(() => store.state.usuario);

	const start = async () => {
		if (!data.value.de) {
			data.value.de = moment().startOf("day").toDate();
		}

		if (!data.value.ate) {
			data.value.ate = moment().endOf("day").toDate();
		}

		const response = await api.post(`/v1/chatv2/resumo`, {...data.value});
		if (!response.data.success) {
			return toast("Erro ao puxar dados da tabela de conversas", "error");
		}

		conversas.value = response.data.conversas;
	};

	const download = async () => {
		if (campanhas.value.length === 1) {
			return toast("Sem campanhas para realizar download", "error");
		}

		try {
			const response = await api.post("/v1/zap/download/home", {campanhas: campanhas.value}, {responseType: "blob"});

			const blob = new Blob([response.data], {type: "text/csv"});

			const downloadUrl = window.URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = downloadUrl;
			link.download = "home.csv";
			link.click();

			window.URL.revokeObjectURL(downloadUrl);
		} catch (error) {
			console.error("Erro ao realizar download", error);
			toast("Erro ao realizar download", "error");
		}
	};

	const getResumoDiarioCampanhas = async () => {
		if (!data.value.de) {
			data.value.de = moment().startOf("day").toDate();
		}

		if (!data.value.ate) {
			data.value.ate = moment().endOf("day").toDate();
		}

		const response = await api.post(`/v1/resumo-diario/`, {...data.value});

		if (!response.data.success) {
			return toast("Erro ao puxar dados da tabela de conversas", "error");
		}

		campanhas.value = response.data.data;
	};

	const getPorcentagem = (valorParcial, valorTotal) => {
		const porcentagem = (valorParcial * 100) / valorTotal;

		if (isNaN(porcentagem)) return 0;

		return porcentagem.toFixed(2);
	};

	const atualizarRelatorio = async () => {
		await Promise.all([getResumoDiarioCampanhas(), start()]);
	};

	const mostraModal = () => $vfm.show("baixarVolumeLzv2");

	onBeforeMount(async () => {
		exibirLoad.value = true;
		await Promise.all([getResumoDiarioCampanhas(), start()]);
		exibirLoad.value = false;
	});
</script>
