<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina :titulo="'Informações do ticket: ' + ticket.numero" />

		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-6">
				<button @click="router.back()" class="mb-5 p-2 rounded-full mr-4 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 flex items-center active:bg-gray-400 active:rounded-full">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
					<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
				</button>
			</div>
			<div class="col-span-12 md:col-span-6 justify-self-end">
				<button v-if="ticket.ativo" @click="ativaDesativaTicket" class="mb-5 px-6 py-2 bg-red-700 rounded-full flex items-center hover:bg-red-800">
					<span class="select-none cursor-pointer text-sm dark:text-white">Fechar ticket</span>
				</button>
				<button v-else @click="ativaDesativaTicket" class="mb-5 px-6 py-2 bg-blue-700 rounded-full flex items-center hover:bg-blue-800">
					<span class="select-none cursor-pointer text-sm dark:text-white">Abrir ticket</span>
				</button>
			</div>
		</div>


		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Nome:</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ ticket.userName }}</span>
			</div>
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Status:</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ ticket.status }}</span>
			</div>
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Prioridade:</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ ticket.prioridade }}</span>
			</div>
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Data de criação</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ moment(ticket.dataCadastro).format("DD/MM HH:mm") }}</span>
			</div>
		</div>
		<div class="grid grid-cols-12 gap-4 my-3">
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Categoria</label>
				<span class="text-lg dark:text-white whitespace-nowrap py-1 px-1 rounded bg-slate-200/[.04]">{{ ticket.categoria }}</span>
			</div>
		</div>
		<hr />

		<hr />
		<div class="grid gap-y-3 gap-x-3 my-3">
			<div class="col-span-12 md:col-span-3">
				<label class="block text-sm dark:text-zinc-200 text-header font-medium">Titulo:</label>
				<span class="text-lg dark:text-white whitespace-nowrap py-1 px-1 rounded bg-slate-200/[.04]">{{ ticket.titulo }}</span>
			</div>
			<div class="col-span-12 md:col-span-3">
				<label class="block text-sm dark:text-zinc-200 text-header font-medium">Descrição:</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ ticket.descricao }}</span>
			</div>
		</div>
		<hr />
		<div class="grid grid-cols-3 gap-6" ref="comentarioDiv">
			<button
				type="button"
				@click="toggleComentario()"
				class="bg-green-600 my-5 p-2 font-medium h-10 text-white text-sm border-b-2 border-r-2 border-green-800 rounded-md block transition duration-300 ease-in-out hover:shadow-md hover:bg-green-800 hover:text-opacity-50"
			>
				+ Comentário
			</button>
			<div>
				<label class="block text-header text-center font-medium mt-1 dark:text-zinc-200"> Mude o status do ticket </label>
				<v-select
					v-model="ticket.status"
					:options="statusOptions"
					:placeholder="ticket.status"
					class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					@option:selected="changeStatus"
				/>
			</div>
		</div>

		<div>
			<div v-if="showInputComentario">
				<div class="col-span-12 md:col-span-12 justify-end">
					<label for="comentarios" class="block text-sm text-white font-medium">Adicionar o comentário</label>
					<textarea
						placeholder="Adicione um novo comentário."
						v-model="newcomentario"
						rows="2"
						type="text"
						name="comentario"
						id="comentario"
						class="mt-1 mb-1 p-1 bg-zinc-500 block w-full focus:border-zinc-600 shadow-sm sm:text-sm rounded-md"
					></textarea>
					<button
						@click="toggleComentario"
						type="button"
						class="bg-gray-600 hover:bg-gray-700 focus:shadow-sm mt-0.5 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
					>
						Cancelar
					</button>
					<button
						@click="comment()"
						type="button"
						class="bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm mx-2 mt-0.5 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
					>
						Salvar
					</button>
				</div>
			</div>
		</div>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200" v-if="comentarios.length > 0">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Data</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">User</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Comentários</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr
						class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800"
						v-for="comentario in comentarios.slice().reverse()"
						:key="comentario._id"
						:class="comentario.root ? 'bg-blue-300/[.08]' : ''"
					>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ moment(comentario.data).format("DD/MM HH:mm") }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ comentario.root ? `Admin - ${comentario.userName}` : `User - ${comentario.userName}` }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ comentario.conteudo }}</th>
					</tr>
				</tbody>
			</table>
			<p v-else class="text-center font-bold text-gray-500 dark:text-zinc-200">Ops, sem Comentários até o momento!</p>
		</div>
	</div>
</template>
<script setup>
	import {onBeforeMount, ref, nextTick} from "vue";
	import {useRoute, useRouter} from "vue-router";
	import {toast} from "@/hooks/toast";
	import api from "@/services/api.js";
	import moment from "moment";
	import TituloPagina from "@/components/tituloPagina.vue";

	const route = useRoute();
	const router = useRouter();

	const ticket = ref({});
	const comentarios = ref([]);
	const newcomentario = ref("");
	const selectedStatus = ref(null);
	const statusOptions = ref(["Em aberto", "Em investigação", "Pendente de informações", "Concluído"]);

	const showInputComentario = ref(false);
	const comentarioDiv = ref(null);

	const toggleComentario = async () => {
		showInputComentario.value = !showInputComentario.value;

		if (showInputComentario.value) {
			await nextTick();
			comentarioDiv.value.scrollIntoView({behavior: "smooth"});
		}
	};

	const comment = async () => {
		if (!newcomentario.value) {
			return toast("insira algum comentario antes de salvar!", "error");
		}

		const req = await api.put(`/v1/ticket/${route.params.id}/comentario`, {conteudo: newcomentario.value});

		if (req.data.success) {
			toast("Salvo com sucesso!", "success");

			const req = await api.get(`/v1/ticket/${route.params.id}`);
			ticket.value = req.data;
			comentarios.value = req.data.comentarios;
			newcomentario.value = "";
			showInputComentario.value = false;
		} else {
			return toast(req.data.err);
		}
	};

	const changeStatus = async () => {
		const req = await api.put(`/v1/ticket/${route.params.id}`, ticket.value);

		if (req.data.success) {
			toast("Status alterado!", "success");

			const req = await api.get(`/v1/ticket/${route.params.id}`);
			ticket.value = req.data;
		} else {
			return toast(req.data.err);
		}
	};

	const ativaDesativaTicket = async () => {
		ticket.value.ativo = !ticket.value.ativo;
		const req = await api.put(`/v1/ticket/${route.params.id}`, ticket.value);

		if (req.data.success) {
			toast("Ticket fechado com sucesso!", "success");

			router.push("/admin/suporte");
		} else {
			return toast(req.data.err);
		}
	};

	onBeforeMount(async () => {
		const id = route.params.id;

		if (id) {
			const req = await api.get(`/v1/ticket/${id}`);
			ticket.value = req.data;
			comentarios.value = req.data.comentarios;
		}
	});
</script>
