<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Suporte" />
		<div class="my-5">
			<form @submit.prevent="fetchData()" class="grid grid-cols-8 gap-3">
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Número</label>
					<CampoDeTexto id="number" tipo="text" class="h-8 w-full" holder="Número do ticket" v-model="filtro.numero" />
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Usuário</label>
					<CampoDeTexto id="text" tipo="text" class="h-8 w-full" holder="Nome do usuario" v-model="filtro.usuario" />
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Status</label>
					<v-select v-model="filtro.status" :options="acoes" placeholder="Nenhuma em específico" class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark" />
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Prioridade</label>
					<v-select
						v-model="filtro.prioridade"
						:options="prioridade"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Categorias</label>
					<v-select
						v-model="filtro.categoria"
						:options="categorias"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
					/>
				</div>
				<div class="col-span-12 sm:col-span-3 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">Ativo</label>
					<v-select
						v-model="filtro.ativo"
						:options="modulos"
						label="nome"
						placeholder="Nenhum específico"
						class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark"
						:reduce="option => (option ? option.acao : null)"
					/>
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">
						Data de
						<input
							type="date"
							@input="event => (filtro.de = event.target.value)"
							:value="filtro.de"
							class="w-full mt-2 border border-gray-200 rounded-md text-center h-8 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
						/>
					</label>
				</div>
				<div class="col-span-12 md:col-span-2">
					<label class="block text-header font-medium mb-2 dark:text-zinc-200">
						Até
						<input
							type="date"
							@input="event => (filtro.ate = event.target.value)"
							:value="filtro.ate"
							class="w-full mt-2 border border-gray-200 rounded-md text-center h-8 dark:bg-zinc-800 dark:border-zinc-700 dark:text-white"
						/>
					</label>
				</div>
				<button
					type="submit"
					class="ml-0 sm:ml-0 dark:border-zinc-900 h-8 mt-8 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Buscar
				</button>
				<button
					@click="router.push(`/admin/suporte/notify`)"
					class="ml-0 sm:ml-0 dark:border-zinc-900 h-10 mt-8 dark:bg-zinc-800 dark:hover:bg-zinc-900 bg-blue-600 font-medium text-white text-sm border-b-2 border-r-2 border-blue-800 rounded-md w-full transition duration-300 ease-in-out hover:shadow-md hover:bg-blue-800 hover:text-opacity-50"
				>
					Notificações do sistema
				</button>
			</form>
		</div>

		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200" v-if="tickets.length > 0">
				<thead class="select-none">
					<tr class="text-header sm:text-left text-sm sm:text-base">
						<th class="sm:px-2 py-3 dark:text-zinc-200">Número</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Usuário</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Cliente</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Status</th>
						<th class="sm:px-2 py-3 dark:text-zinc-200">Prioridade</th>
						<th class="sm:px-2 py-3 text-center dark:text-zinc-200">Titulo</th>
						<th class="sm:px-2 py-3 text-end dark:text-zinc-200">Detalhes</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-sm sm:text-left my-2 hover:bg-gray-200 dark:hover:bg-zinc-800" v-for="ticket in tickets" :key="ticket._id">
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ ticket.numero }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ ticket.userName }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ ticket.usuario ? ticket.usuario.nome : "Cliente" }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ ticket.status }}</th>
						<th class="sm:px-2 py-3 text-green-600 font-medium">{{ ticket.prioridade }}</th>
						<th class="sm:px-2 py-3 text-center text-green-600 font-medium">{{ ticket.titulo }}</th>
						<th class="text-end">
							<button
								@click="router.push(`/admin/suporte/detalhes/${ticket._id}`)"
								class="mx-1 mb-1 mt-2 rounded-md px-2 py-1 text-sm bg-yellow-600 text-white border-b-2 border-r-2 border-yellow-800 transition duration-300 ease-in-out hover:shadow-md hover:bg-yellow-800 hover:text-opacity-50"
							>
								Detalhes
							</button>
						</th>
					</tr>
				</tbody>
			</table>
			<p v-else class="text-center font-bold text-gray-500 dark:text-zinc-200">Ops, sem tickets abertos no momento!</p>

			<Pagination :paginaInicial="pagination.initialPage" :pageCount="pagination.pageCount" :fetchPagination="fetchPagination" />
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import api from "@/services/api.js";
	import Pagination from "@/components/pagination.vue";
	import TituloPagina from "@/components/tituloPagina.vue";
	import CampoDeTexto from "@/components/campoDeTexto.vue";
	import {pega_usuarios} from "@/store/typeActions";
	import {useStore} from "vuex";
	import {toast} from "@/hooks/toast";
	import {useRouter} from "vue-router";

	const store = useStore();
	const router = useRouter();
	const tickets = ref([]);
	const pagination = ref({pageCount: 0, initialPage: 1});
	const filtro = ref({usuario: "", categoria: "", status: "", ativo: null, prioridade: "", numero: "", de: "", ate: "", skip: 0, limit: 50});
	const modulos = [{nome: "Ativo", acao: true}, {nome: "Inativo", acao: false}, ,];
	const acoes = ["Em aberto", "Em investigação", "Pendente de informações", "Concluído"];
	const categorias = ["Falha de envio massivo", "Falha na iniciação manual", "Retorno não presentes na plataforma", "Problemas de relatório ou status", "Outros"];
	const prioridade = ["Alta", "Média", "Baixa"];

	const limit = 50;

	const fetchData = async () => {
		const response = await api.post("/v1/ticket/list", filtro.value);

		if (!response.data.success) return toast(response.data.err, "error");

		const perPage = response.data.ticket.length / limit;
		tickets.value = response.data.ticket;

		pagination.value.pageCount = Math.ceil(perPage);
		limpar();
	};
	const limpar = () => {
		filtro.value = {
			usuario: "",
			categoria: "",
			status: "",
			ativo: null,
			prioridade: "",
			de: "",
			ate: "",
			skip: 0,
			limit: 50,
		};
	};

	const fetchPagination = async page => {
		window.scrollTo(0, 0);
		filtro.value.skip = (page - 1) * filtro.value.limit;
		pagination.value.initialPage = page;

		await fetchData();
	};

	onBeforeMount(async () => {
		await fetchData();
		await store.dispatch(pega_usuarios, true);
	});
</script>
