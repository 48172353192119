<template>
	<div class="my-2 mx-4 md:my-5 md:mx-8">
		<TituloPagina titulo="Notificação" />

		<div class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-6">
				<button
					@click="router.back()"
					class="mb-5 p-2 rounded-full mr-4 hover:bg-gray-200 dark:hover:bg-zinc-600 dark:active:bg-zinc-700 flex items-center active:bg-gray-400 active:rounded-full"
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
						<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
					</svg>
					<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
				</button>
			</div>
			<div v-if="route.params.id" class="col-span-12 md:col-span-6 justify-self-end">
				<button @click="fecharAviso" class="mb-5 px-6 py-2 bg-red-700 rounded-full flex items-center hover:bg-red-800">
					<span class="select-none cursor-pointer text-sm dark:text-white">Fechar Aviso</span>
				</button>
			</div>
		</div>

		<div v-if="route.params.id" class="grid grid-cols-12 gap-4">
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Categoria:</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ aviso.nivel }}</span>
			</div>
			<div class="col-span-12 md:col-span-3">
				<label class="block text-header font-semibold dark:text-zinc-200">Data de criação</label>
				<span class="text-lg dark:text-white py-1 px-1 rounded bg-slate-200/[.04]">{{ moment(aviso.dataInicio).format("DD/MM HH:mm") }}</span>
			</div>
		</div>

		<hr />

		<hr />
		<div class="grid grid-cols-12 gap-y-3 gap-x-3 my-3">
			<div class="col-span-12 md:col-span-3">
				<div>
					<label class="block text-header text-center font-medium mt-1 dark:text-zinc-200"> Nivel do Aviso </label>
					<v-select v-model="aviso.nivel" :options="statusOptions" placeholder="Altere o nivel" class="bg-white dark:bg-zinc-800 dark:text-white dark:select-dark" />
				</div>
			</div>

			<div class="col-span-12 md:col-span-12">
				<label class="block text-sm dark:text-zinc-200 text-header font-medium">Titulo:</label>
				<textarea
					placeholder="Adicione um novo comentário."
					v-model="aviso.titulo"
					rows="2"
					type="text"
					name="comentario"
					id="comentario"
					class="mt-1 mb-1 p-1 bg-zinc-500 block w-full focus:border-zinc-600 shadow-sm sm:text-sm rounded-md"
				></textarea>
			</div>
			<div class="col-span-12 md:col-span-12">
				<label class="block text-sm dark:text-zinc-200 text-header font-medium">Descrição:</label>
				<textarea
					placeholder="Adicione um novo comentário."
					v-model="aviso.descricao"
					rows="2"
					type="text"
					name="comentario"
					id="comentario"
					class="mt-1 mb-1 p-1 bg-zinc-500 block w-full focus:border-zinc-600 shadow-sm sm:text-sm rounded-md"
				></textarea>
			</div>
			<div class="col-span-12 md:col-span-12 justify-self-end">
				<button v-if="route.params.id" @click="salvarAviso" class="mb-5 px-6 py-2 bg-yellow-600 rounded-full flex items-center hover:bg-yellow-700">
					<span class="select-none cursor-pointer text-sm dark:text-white">Editar Aviso</span>
				</button>
				<button v-else @click="salvarAviso" class="mb-5 px-6 py-2 bg-green-700 rounded-full flex items-center hover:bg-green-800">
					<span class="select-none cursor-pointer text-sm dark:text-white">Abrir Aviso</span>
				</button>
			</div>
		</div>
		<hr />
	</div>
</template>
<script setup>
	import {onBeforeMount, ref, nextTick} from "vue";
	import {useRoute, useRouter} from "vue-router";
	import {toast} from "@/hooks/toast";
	import api from "@/services/api.js";
	import moment from "moment";
	import TituloPagina from "@/components/tituloPagina.vue";

	const route = useRoute();
	const router = useRouter();

	const aviso = ref({});
	const statusOptions = ref(["Baixo", "Médio", "Alta"]);

	const fecharAviso = async () => {
		aviso.value.ativo = !aviso.value.ativo;

		if (route.params.id) {
			const req = await api.put(`/v1/notify/`, aviso.value);

			if (req.data.success) {
				toast("Aviso fechado com sucesso!", "success");

				return router.push("/admin/suporte/notify");
			} else {
				return toast(req.data.err);
			}
		}

		const req = await api.post(`/v1/notify`, aviso.value);

		if (req.data.success) {
			toast("Aviso criado com sucesso!", "success");

			return router.push("/admin/suporte/notify");
		} else {
			return toast(req.data.err);
		}
	};

	const isFormValid = () => {
		return aviso.value.titulo !== "" && aviso.value.descricao !== "" && aviso.value.nivel !== "";
	};

	const salvarAviso = async () => {
		if (!isFormValid()) {
			toast("Por favor, preencha todos os campos obrigatórios.");
			return;
		}

		const method = route.params.id ? "put" : "post";
		const req = await api[method](`/v1/notify`, aviso.value);

		if (req.data.success) {
			toast("Notificação salva com sucesso!", "success");
			return router.push("/admin/suporte/notify");
		} else {
			return toast(req.data.err);
		}
	};

	onBeforeMount(async () => {
		const id = route.params.id;

		if (id) {
			const req = await api.get(`/v1/notify/${id}`);

			aviso.value = req.data;
		}
	});
</script>
