<template>
	<div class="my-2 mx-4 md:my-5 md:mx-">
		<button
			class="my-5 p-2 rounded-full mr-4 flex items-center dark:hover:bg-zinc-600 dark:active:bg-zinc-700 hover:bg-gray-200 active:bg-gray-400 active:rounded-full"
			@click="() => router.back()"
		>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-4 w-6 h-6 dark:text-white">
				<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
			</svg>
			<span class="select-none cursor-pointer text-lg dark:text-white">Voltar</span>
		</button>
		<div class="bg-white dark:bg-black my-5 px-2 py-2 shadow rounded-md overflow-auto">
			<table class="w-full divide-y divide-gray-200 dark:divide-zinc-200 text-sm sm:text-base overflow-auto">
				<thead class="select-none cursor-pointer">
					<tr class="text-center sm:text-left text-header">
						<th class="px-2 py-3 dark:text-zinc-200">PC</th>
						<th class="px-2 py-3 dark:text-zinc-200">Abas</th>
						<th class="px-2 py-3 dark:text-zinc-200">UsoCpu</th>
						<th class="px-2 py-3 dark:text-zinc-200">Memória %</th>
						<th class="px-2 py-3 dark:text-zinc-200">Disco %</th>
						<th class="px-2 py-3 dark:text-zinc-200">Memória Total</th>
						<th class="px-2 py-3 dark:text-zinc-200">Memória Usada</th>
						<th class="px-2 py-3 dark:text-zinc-200">Disco Total</th>
						<th class="px-2 py-3 dark:text-zinc-200">Disco Usado</th>
						<th class="px-2 py-3 dark:text-zinc-200">Ultima Att</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-gray-200 dark:divide-zinc-200">
					<tr class="text-center sm:text-left text-sm my-2 hover:bg-gray-200 dark:hover:bg-zinc-800 cursor-default" v-for="(pc, index) in pcs" :key="index">
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700 text-center">{{ pc.pcName }}</td>
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700 text-center">{{ pc.quantidadeNumero }}</td>
						<td class="mx-1 px-2 py-3 font-normal text-center" :class="checkNumber(pc.cpuPercent)">{{ pc.cpuPercent }}%</td>
						<td class="mx-1 px-2 py-3 font-normal text-center" :class="checkNumber(pc.memoriaPercent)">{{ pc.memoriaPercent }}%</td>
						<td class="mx-1 px-2 py-3 font-normal text-center" :class="checkNumber(pc.discoPercent)">{{ pc.discoPercent }}%</td>
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700 text-center">{{ pc.memoriaTotal }}</td>
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700 text-center">{{ pc.memoriaUsada }}</td>

						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700 text-center">{{ pc.discoTotal }}</td>
						<td class="mx-1 px-2 py-3 dark:text-zinc-200 font-normal text-gray-700 text-center">{{ pc.discoUsado }}</td>
						<td class="mx-1 px-2 py-3 font-normal" :class="chekData(pc.ultimaAtt)">{{ pc.ultimaAtt }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script setup>
	import {ref, onBeforeMount} from "vue";
	import api from "@/services/api.js";
	import moment from "moment";
	import {useRouter} from "vue-router";

	const pcs = ref([]);
	const router = useRouter();

	const checkNumber = number => {
		if (number > 85) {
			return "text-red-600";
		} else {
			return "dark:text-zinc-200 text-gray-700";
		}
	};

	const chekData = dateStr => {
		const providedDate = moment(dateStr, "DD-MM HH:mm");

		const now = moment();

		const diffInHours = now.diff(providedDate, "hours");

		if (diffInHours > 21) {
			return "text-red-600";
		} else {
			return "dark:text-zinc-200 text-gray-700";
		}
	};

	const fetchData = async () => {
		const response = await api.get("v1/pcInformacao");

		pcs.value = response.data.data;
	};

	onBeforeMount(async () => await fetchData());
</script>
